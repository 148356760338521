var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm work-card",
                  attrs: { title: "기준정보", noMarginPadding: true },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editable && !_vm.disabled,
                                expression: "editable && !disabled",
                              },
                            ],
                            attrs: {
                              isSubmit: _vm.isSave,
                              url: _vm.saveUrl,
                              param: _vm.workPermit,
                              mappingType: _vm.mappingType,
                              label: _vm.saveBtnLabel,
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveWorkPermit,
                              btnCallback: _vm.saveWorkPermitCallback,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "card-detail" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-4 col-md-2 col-lg-2 work-item",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              label: "LBLPLANT",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.workPermit.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.workPermit, "plantCd", $$v)
                              },
                              expression: "workPermit.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-4 col-md-2 col-lg-2 work-item",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              disabled: true,
                              editable: _vm.editable,
                              label: "허가번호",
                              name: "permitNo",
                            },
                            model: {
                              value: _vm.workPermit.permitNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.workPermit, "permitNo", $$v)
                              },
                              expression: "workPermit.permitNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-4 col-md-2 col-lg-2 work-item",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              range: true,
                              required: true,
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              label: "허가작업기간",
                              name: "permitPeriod",
                            },
                            model: {
                              value: _vm.workPermit.permitPeriod,
                              callback: function ($$v) {
                                _vm.$set(_vm.workPermit, "permitPeriod", $$v)
                              },
                              expression: "workPermit.permitPeriod",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-4 col-md-2 col-lg-2 work-item",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: true,
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              range: true,
                              minuteStep: 10,
                              type: "time",
                              label: "작업시간",
                              name: "workTime",
                            },
                            model: {
                              value: _vm.workPermit.workTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.workPermit, "workTime", $$v)
                              },
                              expression: "workPermit.workTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 work-item",
                        },
                        [
                          _c("c-multi-select", {
                            attrs: {
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              codeGroupCd: "SOP_SUPPLEMENT_WORK_TYPE_CD",
                              itemText: "codeName",
                              itemValue: "code",
                              label: "보충작업",
                              name: "supWorks",
                            },
                            on: { datachange: _vm.changeSupWork },
                            model: {
                              value: _vm.supWorks,
                              callback: function ($$v) {
                                _vm.supWorks = $$v
                              },
                              expression: "supWorks",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.isWorkInfo
                        ? [
                            _c("q-slide-transition", [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.visible,
                                      expression: "visible",
                                    },
                                  ],
                                  staticClass: "row",
                                  staticStyle: {
                                    display: "-webkit-inline-box",
                                    width: "100%",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 grid-style-transition",
                                    },
                                    [
                                      _c(
                                        "q-card",
                                        {
                                          staticClass: "work-card",
                                          class: _vm.completeFlag
                                            ? "bg-highLight"
                                            : "",
                                          attrs: { flat: "", bordered: "" },
                                        },
                                        [
                                          _c(
                                            "q-card-section",
                                            {
                                              staticClass:
                                                "q-pa-xs q-pl-sm bg-grey-3",
                                              class:
                                                this.editable && !this.disabled
                                                  ? "cursor-pointer"
                                                  : "cursor-not-allowed",
                                              on: {
                                                click: _vm.qCardCompleteClick,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-orange-9 work-title-text",
                                                },
                                                [
                                                  _c("q-checkbox", {
                                                    attrs: {
                                                      size: "22px",
                                                      label: "",
                                                      disable: true,
                                                    },
                                                    on: {
                                                      input: _vm.changeComplete,
                                                    },
                                                    model: {
                                                      value: _vm.completeFlag,
                                                      callback: function ($$v) {
                                                        _vm.completeFlag = $$v
                                                      },
                                                      expression:
                                                        "completeFlag",
                                                    },
                                                  }),
                                                  _vm._v(" 완료 "),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c("q-separator"),
                                          _c(
                                            "q-card-section",
                                            { staticClass: "no-padding row" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-4 work-item",
                                                },
                                                [
                                                  _c("c-datepicker", {
                                                    attrs: {
                                                      required:
                                                        _vm.completeFlag,
                                                      editable: _vm.editable,
                                                      disabled:
                                                        _vm.disabled ||
                                                        !_vm.completeFlag,
                                                      "time-picker-options":
                                                        _vm.completeTimeOption,
                                                      start:
                                                        _vm.workPermit
                                                          .permitDate,
                                                      minuteStep: 10,
                                                      type: "time",
                                                      label: "완료일시",
                                                      beforeText: "",
                                                      name: "workCompleteTime",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.workPermit
                                                          .workCompleteTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.workPermit,
                                                          "workCompleteTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "workPermit.workCompleteTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-4 work-item",
                                                },
                                                [
                                                  _c("c-field", {
                                                    attrs: {
                                                      editable: _vm.editable,
                                                      disabled: true,
                                                      data: _vm.workPermit,
                                                      deptValue:
                                                        "workCompleteEntryDeptCd",
                                                      type: "dept_user",
                                                      label: "확인자",
                                                      beforeText: "",
                                                      name: "workCompleteEntryUserId",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.workPermit
                                                          .workCompleteEntryUserId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.workPermit,
                                                          "workCompleteEntryUserId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "workPermit.workCompleteEntryUserId",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-4 work-item",
                                                },
                                                [
                                                  _c("c-text", {
                                                    attrs: {
                                                      editable: _vm.editable,
                                                      disabled:
                                                        _vm.disabled ||
                                                        !_vm.completeFlag,
                                                      label: "입회자",
                                                      beforeText: "",
                                                      name: "workCompleteWorkUserName",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.workPermit
                                                          .workCompleteWorkUserName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.workPermit,
                                                          "workCompleteWorkUserName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "workPermit.workCompleteWorkUserName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-12 work-item",
                                                },
                                                [
                                                  _c("c-text", {
                                                    attrs: {
                                                      editable: _vm.editable,
                                                      disabled:
                                                        _vm.disabled ||
                                                        !_vm.completeFlag,
                                                      label: "조치사항",
                                                      beforeText: "",
                                                      name: "actionRemark",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.workPermit
                                                          .actionRemark,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.workPermit,
                                                          "actionRemark",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "workPermit.actionRemark",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-12 folder-area" },
                              [
                                _c("q-btn", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "2px",
                                    color: "light-blue",
                                    icon: _vm.folderIcon,
                                  },
                                  on: { click: _vm.folder },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("c-tab", {
        staticClass: "q-mt-sm",
        attrs: {
          type: "tabcard",
          align: "left",
          tabItems: _vm.tabItems,
          inlineLabel: true,
          dense: true,
          height: _vm.tabHeight,
        },
        on: {
          "update:tabItems": function ($event) {
            _vm.tabItems = $event
          },
          "update:tab-items": function ($event) {
            _vm.tabItems = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  key: tab.componentKey,
                  ref: "swp-tab",
                  tag: "component",
                  attrs: {
                    workPermit: _vm.workPermit,
                    supWork: tab.supWork,
                    gridLoto: _vm.gridLoto,
                    isOld: _vm.isOld,
                    isWriting: _vm.isWriting,
                    saveAction: _vm.saveAction,
                    height: _vm.tabHeight,
                  },
                  on: {
                    "update:workPermit": function ($event) {
                      _vm.workPermit = $event
                    },
                    "update:work-permit": function ($event) {
                      _vm.workPermit = $event
                    },
                    "update:supWork": function ($event) {
                      return _vm.$set(tab, "supWork", $event)
                    },
                    "update:sup-work": function ($event) {
                      return _vm.$set(tab, "supWork", $event)
                    },
                    "update:gridLoto": function ($event) {
                      _vm.gridLoto = $event
                    },
                    "update:grid-loto": function ($event) {
                      _vm.gridLoto = $event
                    },
                    validResult: _vm.validResult,
                  },
                }),
              ]
            },
          },
          {
            key: "customTab",
            fn: function () {
              return [
                _c("q-tab", {
                  attrs: {
                    name: "references",
                    icon: "description",
                    label: "관련정보",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "customTabPanel",
            fn: function () {
              return [
                _c(
                  "q-tab-panel",
                  { attrs: { name: "references" } },
                  [
                    _c(
                      "keep-alive",
                      [
                        _c(_vm.referencesCompo, {
                          key: _vm.referencesKey,
                          tag: "component",
                          attrs: {
                            workPermit: _vm.workPermit,
                            supWorks: _vm.supWorks,
                            saveCall: _vm.saveCall,
                            isWriting: _vm.isWriting,
                            height: _vm.tabHeight,
                          },
                          on: {
                            "update:workPermit": function ($event) {
                              _vm.workPermit = $event
                            },
                            "update:work-permit": function ($event) {
                              _vm.workPermit = $event
                            },
                            "update:supWorks": function ($event) {
                              _vm.supWorks = $event
                            },
                            "update:sup-works": function ($event) {
                              _vm.supWorks = $event
                            },
                            "update:saveCall": function ($event) {
                              _vm.saveCall = $event
                            },
                            "update:save-call": function ($event) {
                              _vm.saveCall = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }